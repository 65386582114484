<template>
  <section id="dashboard-analytics">
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <feather-icon
              icon="LayoutIcon"
              size="34"
              class="mr-50 text-primary"
            />
          </div>
          <div class="my-auto flex-grow-1">
            <b-card-title class="mb-25 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                {{ company.name }}
                <b-badge
                  :variant="`light-${mapPackageToVariant(company.packageId).variant}`"
                >
                  {{ $t('PACKAGE') }} : {{ mapPackageToVariant(company.packageId).name }}
                </b-badge>
              </div>
              <feather-icon
                v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
                :id="`company-edit-${company.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'company-configuration-id', params: { id: company.id }})"
              />
            </b-card-title>
          </div>
        </div>
        <!-- start date -->
        <b-media
          no-body
        >
          <b-media-aside
            v-if="company.addressLine1"
            class="mr-1"
          >
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="MapPinIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body v-if="company.addressLine1">
            <h6 class="mt-0 pt-50">
              {{ company.addressLine1 }} , {{ company.addressLine2 }} , {{ company.postcode }} , {{ company.city }}  , {{ company.country }}
            </h6>
          </b-media-body>
        </b-media>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <b-tabs>
          <b-tab
            :title="$t('Projects')"
            active
          >
            <b-card-text>
              <company-project-view />
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('USERS')"
          >
            <b-card-text>
              <company-user-view />
            </b-card-text>
          </b-tab>
          <b-tab
            :title="$t('MEDIAS')"
          >
            <b-card-text>
              <company-media-view :company-id="companyId" />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  VBTooltip,
  BMediaBody,
  BBadge,
  BTabs,
  BTab,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'
import { mapPackageToVariant, updateBreadcrumb } from '@/constants/utils'
import constants from '@/constants/static.json'
import useJwt from '@/auth/jwt/useJwt'
import CompanyUserView from './CompanyUserView.vue'
import CompanyProjectView from './CompanyProjectView.vue'
import CompanyMediaView from './CompanyMediaView.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    CompanyUserView,
    CompanyProjectView,
    CompanyMediaView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      breadCrumb: constants.BREADCRUMB,
      companyId: 0,
    }
  },
  computed: {
    ...mapState('company', ['company']),
  },
  async created() {
    const { id } = router.currentRoute.params
    this.companyId = id ? parseInt(id, 10) : this.userData?.companyId
    await this.fetchCompanyById(this.companyId)
    this.setCompanyBreadcrumb()
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyById',
    ]),
    setCompanyBreadcrumb() {
      if (this.company.name) {
        updateBreadcrumb(this, [
          { text: this.company.name, active: true },
        ])
        useJwt.setBreadcrumb(this.breadCrumb.COMPANY, { id: this.company.id, name: this.company.name })
      }
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      mapPackageToVariant,
      updateBreadcrumb,
    }
  },

}
</script>
