<template>
  <b-card
    v-if="users"
    no-body
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <b-button
            v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
            variant="primary"
            :to="{
              name: 'user-configuration',
              query: {
                companyId: companyId,
              },
            }"
          >
            <span> {{ $t('ADD_USER') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      :items="paginatedUsers"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >
      <!-- Column: Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`user-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="UserIcon"
              />
            </b-avatar></template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.firstName }}  {{ data.item.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>
      <!-- Column:-- Role -->
      <template #cell(Role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="mapUserRoleToVariant(data.item.userRoleId).Icon"
            size="18"
            class="mr-50"
            :class="`text-${mapUserRoleToVariant(data.item.userRoleId).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ mapUserRoleToVariant(data.item.userRoleId).name }}</span>
        </div>
      </template>
      <!-- Column: Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <small class="text-muted">{{ data.item.lastLogin ? formatDateTime(data.item.lastLogin) : $t('NEVER_LOGGED') }}</small>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-show="(userData.userRoleId == userRole.ADMIN || userData.userRoleId == userRole.COMPANY_ADMIN)"
            :id="`user-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="$router.push({ name: 'user-configuration-id', params: { id: data.item.id }})"
          />

          <feather-icon
            :id="`user-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.id }})"
          />

          <feather-icon
            v-show="(userData.userRoleId == userRole.ADMIN || userData.userRoleId == userRole.COMPANY_ADMIN)"
            :id="`user-row-${data.item.id}-unlock-icon`"
            icon="UnlockIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'user-password-id', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="(userData.userRoleId == userRole.ADMIN || userData.userRoleId == userRole.COMPANY_ADMIN)"
            :id="`user-row-${data.item.id}-delete-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="confirmAndDeleteUser(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import {
  mapUserRoleToVariant,
} from '@/constants/utils'
import { mixinDate } from '@/constants/mixinDate'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
    BButton,
  },
  mixins: [mixinDate],
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      companyId: 0,
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'Name', sortable: false },
        { key: 'Role', sortable: false },
        { key: 'LastLogin', sortable: false },
        { key: 'Actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState('user', ['allUsers']),
    totalUsers() {
      return this.allUsers.length
    },
    dataMeta() {
      const localItemsCount = this.allUsers.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.allUsers.slice(start, end)
    },
  },
  created() {
    const { id } = router.currentRoute.params
    this.companyId = id || this.userData.companyId
    this.fetchUsersByCompanyId(this.companyId)
  },
  methods: {
    ...mapActions('user', [
      'deleteUser',
      'fetchUsersByCompanyId']),
    confirmAndDeleteUser(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.firstName} ${data.lastName}  ${this.$i18n.t('User')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteUser(data)
          }
        })
    },
    handleDeleteUser(data) {
      if (data) {
        this.deleteUser(data.id).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.USER_DELETED'))
            this.fetchUsersByCompanyId(this.companyId)
          }
        }).catch(error => {
          if (error.response?.data?.status === 'failure') {
            this.errorMessage(error.response.data.message.toString())
          }
        })
      }
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      mapUserRoleToVariant,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
