<template>
  <b-card
    v-if="allProjects"
    no-body
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <b-button
            v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
            variant="primary"
            :to="{
              name: 'project-configuration',
              query: {
                companyId: companyId,
              },
            }"
          >
            <span> {{ $t('ADD_PROJECT') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refProjectListTable"
      :items="paginatedProjects"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >
      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`project-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="BoxIcon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,30) }}
          </b-link>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.externalReference }}
          </span>
        </b-media>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`project-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'project-view-id', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end) && (data.item.isAdmin)"
            :id="`project-row-${data.item.id}-mail-icon`"
            icon="MailIcon"
            size="16"
            :class="getIconColor(data.item.projectUsers)"
            class="cursor-pointer mr-1"
            @click="openEmailModel(data.item)"
          />
          <feather-icon
            v-show="(userData.userRoleId == userRole.ADMIN || userData.userRoleId == userRole.COMPANY_ADMIN)"
            :id="`project-row-${data.item.id}-delete-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="confirmAndDeleteProject(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProjects"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <project-email-model
      :project="project"
      :fetch-projects="fetchCompanyProjects"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import useJwt from '@/auth/jwt/useJwt'
import { isTodayOrFutureDate, isUserAdminInProject } from '@/constants/utils'
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'
import ProjectEmailModel from '../../project/project-list/ProjectEmailModel.vue'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
    BButton,
    ProjectEmailModel,
  },
  mixins: [mixinDate, mixinList],
  data() {
    return {
      sortBy: 'id',
      project: {},
      companyId: 0,
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: false },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'start_date', label: 'Start Date', sortable: false },
        { key: 'end_date', label: 'End Date', sortable: false },
        { key: 'Actions' },
      ],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      isTodayOrFutureDate,
      isUserAdminInProject,
    }
  },
  computed: {
    ...mapState('project', ['allProjects']),
    enhancedProjects() {
      return this.allProjects.map(project => ({
        ...project,
        isAdmin: this.isUserAdminInProject(project.projectUsers, this.userData),
      }))
    },
    totalProjects() {
      return this.enhancedProjects.length
    },
    dataMeta() {
      const localItemsCount = this.allProjects.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProjects,
      }
    },
    paginatedProjects() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.enhancedProjects.slice(start, end)
    },
  },
  created() {
    this.fetchCompanyProjects()
  },
  methods: {
    ...mapActions('project', [
      'fetchProjectByCompanyID',
      'deleteProject']),
    async fetchCompanyProjects() {
      try {
        const { id } = router.currentRoute.params
        this.companyId = id || this.userData.companyId
        this.fetchProjectByCompanyID(this.companyId)
      } catch (error) {
        this.showErrorMessage()
      }
    },
    confirmAndDeleteProject(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Projects')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteProject(data)
          }
        })
    },
    getIconColor(projectUsers) {
      if (projectUsers.length === 0) {
        return 'text-muted'
      }
      const hasEmailSent = projectUsers.every(user => user.isSendEmail)
      return hasEmailSent ? 'text-success' : 'text-warning'
    },
    openEmailModel(data) {
      if (data) {
        this.project = { ...data }
      }
      this.$bvModal.show('project-email-modal')
    },
    handleDeleteProject(data) {
      if (data) {
        this.deleteProject(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.PROJECT_DELETED'))
            this.fetchCompanyProjects()
          }
        }).catch(error => {
          if (error.response.data.status === 'failed') {
            this.errorMessage(error.response.data.message.toString())
          }
        })
      }
    },
  },
}

</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
